





































import {Component, Mixins, Prop} from 'vue-property-decorator';
import DynamicForm from '@/components/Forms/DynamicForm.vue';
import DynamicSection from '@/components/Forms/DynamicSection.vue';
import FormMixin from '@/components/Mixin/FormMixin';
import store from "@/store";

@Component({
  components: {DynamicSection}
})
export default class DynamicRelated extends Mixins(FormMixin, DynamicForm) {
  @Prop(String) sectionLabel;
  @Prop(Object) parentFormData;
  @Prop(Object) lineSection;

  addDataToSaveToModel(dataToSave) {
    dataToSave.record = this.parentFormData.data.ID;
    dataToSave.module = this.parentFormData.module;
    return dataToSave;
  }

  async saveToModel(dataToSave, ID = null) {
    dataToSave = this.addDataToSaveToModel(dataToSave);
    if (!this.parentFormData.data.ID && !ID) {
      // save main form before
      this.parentFormData.callbackAfterSave = (ID) => { this.saveToModel(dataToSave, ID) };
      this.$emit('saveTheForm', true);
    } else {
      this.model.save(dataToSave, () => {
        if (this.callbackAfterSave) {
          this.callbackAfterSave(ID);
        }
        if (this.formData.callbackAfterSave) {
          (this.formData as any).callbackAfterSave(ID);
        }
        this.$emit("receiveResetShowForm");
      }).catch(() => {
        store.state.alertMessage = "error";
      });
    }
  }

  formSectionsLocal() {
    if (this.dynSections) {
      return false;
    }
    const result: any = [];
    const sec = {
      id: 'general',
      name: 'general',
      order: 0,
      fields: {
        first: [] as any,
        second: [] as any,
        full: [] as any
      },
      type: 'fieldSection',
      label: this.sectionLabel
    };
    sec.fields.full = Object.values(this.dynFields).
        sort((a: any, b: any) => {
          return a.order - b.order
        });
    result.push(sec);
    return result;
  }

}
